"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
require("@/node_modules/angularjs-slider/dist/rzslider.js");
const action_1 = require("@src/app/services/action");
const check_password_strength_1 = require("check-password-strength");
const randomstring = require("randomstring");
const shuffle = (str) => {
    return str.split('').sort(function () {
        return 0.5 - Math.random();
    }).join('');
};
const defaultOptions = {
    amount: 1,
    length: 15,
    numbers: true,
    lowercaseCharacters: true,
    uppercaseCharacters: true,
    symbols: true,
};
// const passwordStrengthMap = {
//     0: 'Too weak',
//     1: 'Weak',
//     2: 'Medium',
//     3: 'Strong',
//     4: '',
// }
class PasswordsCtrl {
    constructor($scope, $filter, actionService, ConfigService) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.actionService = actionService;
        this.ConfigService = ConfigService;
        this.min = 4;
        this.max = 99;
        this.options = Object.assign(Object.assign({}, defaultOptions), JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{}'));
        this.slider = {
            value: this.options.length,
            options: {
                floor: this.min,
                ceil: this.max,
                step: 1,
                onChange: function (sliderId, modelValue) {
                    this.options.length = modelValue;
                }.bind(this)
            }
        };
        this.generateDisabled = false;
        this.start$ = new rxjs_1.BehaviorSubject(true);
        this.maxIterateOffset = 1000;
        this.symbols = "!\"#$%&\\'()*+,-./:;<=>?@[]^_`{|}~";
    }
    $onInit() {
        this.$scope.$watch('$ctrl.options', (options) => {
            localStorage.setItem(`${this.constructor.name}_options`, JSON.stringify(options));
            if ([
                options.numbers,
                options.lowercaseCharacters,
                options.uppercaseCharacters,
                options.symbols
            ].indexOf(true) < 0) {
                this.generateDisabled = true;
            }
            else {
                this.generateDisabled = false;
            }
        }, true);
        this.start$.pipe((0, operators_1.switchMap)(() => {
            const passwordsPipes = Array(this.options.amount || 1).fill((0, rxjs_1.interval)(10).pipe((0, operators_1.startWith)(0), (0, operators_1.map)((i) => {
                const password = this.generate();
                const strength = (0, check_password_strength_1.passwordStrength)(password);
                return {
                    password: password,
                    passwordStrength: strength.value,
                    strength: strength,
                    delay: 0.05 * Math.pow(i * 10, 2) + 30
                };
            }), (0, operators_1.filter)((state) => {
                return state.password.length == this.options.length;
            }), (0, operators_1.concatMap)((state) => {
                return (0, rxjs_1.timer)(state.delay).pipe(
                // tap(() => {
                //     this.$scope.$apply(() => {
                //         this.error = undefined
                //         this.password = state.password
                //         this.passwordStrength = state.passwordStrength
                //     })
                // }),
                (0, operators_1.mapTo)(state));
            }), 
            // filter((state: any) => {
            //     if (this.options.length > 6) {
            //         return state.strength.id > 1
            //     }
            //     return true
            // }),
            (0, operators_1.take)(10), (0, operators_1.catchError)((err) => {
                console.error(err);
                this.error = err.toString();
                return rxjs_1.NEVER;
            })));
            return (0, rxjs_1.combineLatest)(passwordsPipes).pipe((0, operators_1.map)((values) => {
                let passwords = [];
                for (const state of values) {
                    passwords.push(state.password);
                }
                this.$scope.$apply(() => {
                    this.error = undefined;
                    this.password = passwords.join('\n');
                    this.passwordStrength = values[0].passwordStrength;
                });
            }));
        })).subscribe();
    }
    changeLength() {
        if (this.options.length != undefined)
            this.slider.value = this.options.length;
    }
    generateReel() {
        this.start$.next(true);
    }
    submitForm($form) {
        if ($form.$valid) {
            this.generateReel();
        }
    }
    copy() {
        this.actionService.copyFactory(document.getElementById('password'), this.password);
    }
    generate() {
        const charset = this.getCharacters();
        let password;
        if ((charset.length < this.options.length) && this.ConfigService.cookieSettings.excludeDuplicateCharacters) {
            throw "Not enough characters to generate such a long non-repeating password";
        }
        else if ((charset.length == this.options.length) && this.ConfigService.cookieSettings.excludeDuplicateCharacters) {
            password = shuffle(charset);
        }
        else {
            password = randomstring.generate({
                length: this.options.length,
                charset: charset
            });
            password = this.cleanPassword(password);
            let count = 0;
            while ((password.length < this.options.length) && (count < this.maxIterateOffset)) {
                count += 1;
                password += randomstring.generate({
                    length: this.options.length,
                    charset: charset
                });
                password = this.cleanPassword(password);
            }
            if (count == this.maxIterateOffset) {
                throw 'Infinite generation';
            }
        }
        return password;
    }
    cleanPassword(value) {
        if (this.ConfigService.cookieSettings.excludeSimilarCharacters) {
            value = value.replace(/[1ijIlLo0O5Sb6gq9Z2]/g, '');
        }
        if (this.ConfigService.cookieSettings.excludeAmbiguousCharacters) {
            value = value.replace(/[~,;:.{}()<>/\'`”\[\]]/g, '');
        }
        if (this.ConfigService.cookieSettings.excludeDuplicateCharacters) {
            value = [...new Set(value)].join('');
        }
        if (this.options.symbols) {
            // исключить более одного специального символа
        }
        if (this.ConfigService.cookieSettings.startWithALatter) {
            let charset = '';
            if (this.options.lowercaseCharacters)
                charset += 'abcdefghijklmnopqrstuvwxyz';
            if (this.options.uppercaseCharacters)
                charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            if (charset.length > 0) {
                const randChar = shuffle(charset);
                value = `${randChar[0]}${value}`;
            }
        }
        if (value.length > this.options.length) {
            value = value.slice(0, this.options.length);
        }
        return value;
    }
    getCharacters() {
        let characters = [];
        if (this.options.numbers) {
            for (let a of '0123456789') {
                characters.push(a);
            }
        }
        if (this.options.lowercaseCharacters) {
            for (let a of 'abcdefghijklmnopqrstuvwxyz') {
                characters.push(a);
            }
        }
        if (this.options.uppercaseCharacters) {
            for (let a of 'ABCDEFGHIJKLMNOPQRSTUVWXYZ') {
                characters.push(a);
            }
        }
        if (this.options.symbols) {
            for (let a of this.symbols) {
                characters.push(a);
            }
        }
        if (this.ConfigService.cookieSettings.excludeSimilarCharacters) {
            let index;
            for (let e of "1ijIlLo0O5Sb6gq9Z2") {
                index = characters.indexOf(e);
                if (index >= 0) {
                    characters.splice(index, 1);
                }
            }
        }
        if (this.ConfigService.cookieSettings.excludeAmbiguousCharacters) {
            let index;
            for (let e of "~,;:.{}()<>/\\'`”[]") {
                index = characters.indexOf(e);
                if (index >= 0) {
                    characters.splice(index, 1);
                }
            }
        }
        return characters.join('');
    }
}
PasswordsCtrl.$inject = ['$scope', '$filter', 'ActionService', 'ConfigService'];
const appModule = ng.module('app');
appModule.requires.push('rzSlider');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gamePasswords', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: PasswordsCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', 'ConfigServiceProvider', (WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('passwords/');
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            excludeSimilarCharacters: false,
            excludeAmbiguousCharacters: false,
            excludeDuplicateCharacters: false,
            startWithALatter: false,
        });
    }]);
